import * as React from "react";
import { useState, useEffect } from "react";

const MS_PER_FRAME = 5;
const widthSEILogo = 240.8;
const heightSEILogo = 157.4;

export default function SEILogo({ width, height }) {
  const [{ x, xSpeed }, setX] = useState({
    x: getRandomNumber(0, width - widthSEILogo),
    xSpeed: 1,
  });
  const [{ y, ySpeed }, setY] = useState({
    y: getRandomNumber(0, height - heightSEILogo),
    ySpeed: 1,
  });

  const [r, setR] = useState(getRandomNumber(100, 256));
  const [b, setB] = useState(getRandomNumber(100, 256));
  const [g, setG] = useState(getRandomNumber(100, 256));

  function setRandomColors() {
    setR(getRandomNumber(100, 256));
    setB(getRandomNumber(100, 256));
    setG(getRandomNumber(100, 256));
  }

  function getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  function moveDVDLogo() {
    setX(({ x: old, xSpeed: oldspeed }) => {
      if (old + widthSEILogo >= width || old <= 0) {
        setRandomColors();
        return { x: old - oldspeed, xSpeed: -oldspeed };
      }

      return { x: old + oldspeed, xSpeed: oldspeed };
    });

    setY(({ y: old, ySpeed: oldspeed }) => {
      if (old + heightSEILogo >= height || old <= 0) {
        setRandomColors();
        return { y: old - oldspeed, ySpeed: -oldspeed };
      }

      return { y: old + oldspeed, ySpeed: oldspeed };
    });
  }

  useEffect(() => {
    setInterval(() => moveDVDLogo(), MS_PER_FRAME);
  }, []);

  return (
    <g>
      <g fill={`rgb(${r}, ${g}, ${b})`} transform={`translate(${x}, ${y})`}>
        <path
          d="M92.8,53.5c-0.7,0-1.5,0.1-2.1,0.5L48.3,9.1h6.3l28.5,30.2c-0.4,0.7-0.7,1.6-0.7,2.5c0,2.9,2.4,5.3,5.3,5.3
		s5.3-2.4,5.3-5.3s-2.4-5.3-5.3-5.3c-0.6,0-1.2,0.1-1.8,0.3L59.8,9.1h6.3l13.8,14.6c-0.4,0.7-0.7,1.6-0.7,2.5c0,2.9,2.4,5.3,5.3,5.3
		s5.3-2.4,5.3-5.3s-2.4-5.3-5.3-5.3c-0.6,0-1.2,0.1-1.8,0.3c-5-5.3-10-10.6-15-15.9c-8.3,0-16.7,0-25,0c-7.5,8-14.9,15.8-22.4,23.7
		v16.7L13.2,38c0.3-0.7,0.5-1.4,0.5-2.1c0-2.9-2.4-5.3-5.3-5.3S3,33,3,35.9s2.4,5.3,5.3,5.3c0.8,0,1.5-0.2,2.2-0.5l67.4,71.5v22.2
		h-4v-19.6C55.4,95.2,36.8,75.4,18.2,55.7c0.5-0.8,0.7-1.7,0.7-2.7c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3
		c0.6,0,1.1-0.1,1.7-0.3l55,58.3v6.4L21.4,71.1c0.3-0.7,0.5-1.5,0.5-2.3c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3
		c0.7,0,1.4-0.1,2-0.4l51.6,54.7v6.2H41v-8.3c2-0.7,3.4-2.7,3.4-4.9c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3
		c0,2.3,1.4,4.2,3.5,4.9v8.2h-5.8v-16.6c2-0.7,3.4-2.7,3.4-4.9c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3c0,2.3,1.4,4.2,3.4,4.9v16.5
		h-5.4v-25.1c2-0.7,3.4-2.7,3.4-4.9c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3c0,2.3,1.4,4.2,3.4,4.9v27.4c10.5,6.4,21,12.7,31.5,19.1
		h22.1c5.8-6.2,11.7-12.3,17.5-18.5c0-11.4,0-22.8,0-34.1L45.5,56.5v-25l9.4-9.9L88,56.7c-0.3,0.7-0.5,1.4-0.5,2.2
		c0,2.9,2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3C98.1,55.9,95.7,53.5,92.8,53.5 M87.8,38.8c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1
		c-1.7,0-3.1-1.4-3.1-3.1C84.7,40.2,86.1,38.8,87.8,38.8 M84.6,23.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1
		c-1.7,0-3.1-1.4-3.1-3.1C81.5,24.7,82.9,23.3,84.6,23.3 M8.4,38.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1
		C11.4,37.6,10,38.9,8.4,38.9 M13.6,56.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1
		C16.7,54.7,15.3,56.1,13.6,56.1 M16.7,71.7c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C19.7,70.3,18.3,71.7,16.7,71.7
		 M17.3,104.5c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1C18.7,107.5,17.3,106.1,17.3,104.5 M30.8,30.3v26.4
		l-6.6-7V30.6L43.8,9.8l3.2,3.4C41.6,18.9,36.2,24.6,30.8,30.3 M36.1,121.2c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1
		s-1.4,3.1-3.1,3.1C37.5,124.3,36.1,122.9,36.1,121.2 M26.5,113c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1
		C27.9,116.1,26.5,114.7,26.5,113 M70.4,152H51l-8.4-5h32.6L70.4,152z M78.7,143.2H36.4l-8.3-5h55.4L78.7,143.2z M41.7,29.9
		c0,9.4,0,18.7,0,28l44.1,46.7v29.8h-4v-23.8C66,94,50.3,77.4,34.5,60.7V31.8l15-15.9l2.7,2.9L41.7,29.9z M92.8,61.9
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S94.5,61.9,92.8,61.9"
        />
        <path
          d="M171.5,99.4c-2.9,0-5.3,2.4-5.3,5.3c0,0.8,0.2,1.5,0.5,2.2l-32.5,32.5l-8.3-8.3v-40h11.1
		c0.7,2.1,2.7,3.5,5,3.5c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3c-2.2,0-4.1,1.4-4.9,3.3h-11.2v-5.3h21.4
		c0.7,2.1,2.7,3.5,5,3.5c2.9,0,5.3-2.4,5.3-5.3s-2.4-5.3-5.3-5.3c-2.2,0-4.1,1.3-4.9,3.3h-21.5v-5h32.5c0.7,2,2.7,3.4,4.9,3.4
		c2.9,0,5.3-2.4,5.3-5.3S166,66,163.1,66c-2.3,0-4.2,1.4-4.9,3.4h-32.5v-9.2l7.9-8.6l-2.8-2.5l-30.2,32.9v51.4
		c6.6,6.6,13.1,13.1,19.7,19.7l0,0l2.7,2.7c8.4,0,16.7,0,25,0c4.9-4.9,9.8-9.8,14.7-14.7c0.7,0.3,1.4,0.5,2.2,0.5
		c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3c0,0.7,0.1,1.5,0.5,2.1l-13.4,13.6h-6.3l25.4-25.4
		c0.7,0.3,1.5,0.5,2.3,0.5c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3c0,0.7,0.1,1.4,0.4,2.1L135,151.9h-8.1
		l1.7-1.7c0.9-0.9,1.8-1.8,2.7-2.7l38-38c0.7,0.3,1.4,0.5,2.2,0.5c2.9,0,5.3-2.4,5.3-5.3C176.8,101.8,174.4,99.4,171.5,99.4
		 M141.9,86.3c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C138.9,87.7,140.3,86.3,141.9,86.3 M152.2,77.3
		c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C149.2,78.6,150.6,77.3,152.2,77.3 M163.2,68.4
		c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C160.2,69.7,161.6,68.4,163.2,68.4 M168,118.7
		c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C165,120.1,166.4,118.7,168,118.7 M165.1,133.2
		c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C162,134.5,163.4,133.2,165.1,133.2 M123.2,150.4l-18.6-18.6V83.3l6.5-7
		v56.4l0,0l0,0l14.9,14.9L123.2,150.4z M114.8,131.1V72.2l7.3-8v68.4l9.4,9.4l-2.9,2.9L114.8,131.1z M171.5,107.8
		c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C174.5,106.4,173.2,107.8,171.5,107.8"
        />
        <path
          d="M103.4,70.2l17.1-18.6h0.1v-0.1l7-7.7l-2.8-2.5l-4.2,4.5V26.8h46.3c0.7,2,2.7,3.4,4.9,3.4
		c2.9,0,5.3-2.4,5.3-5.3s-2.4-5.3-5.3-5.3c-2.3,0-4.2,1.4-4.9,3.4h-60.1l4.4-4.8h41.6c0.7,2,2.7,3.4,4.9,3.4c2.9,0,5.3-2.4,5.3-5.3
		s-2.4-5.3-5.3-5.3c-2.3,0-4.2,1.4-4.9,3.4h-38.1l4.9-5.4h18.8c0.7,2,2.7,3.5,5,3.5c2.9,0,5.3-2.4,5.3-5.3s-2.4-5.3-5.3-5.3
		c-2.3,0-4.2,1.4-4.9,3.4c-6.8,0-13.7,0-20.4,0c-5.8,6.3-11.6,12.7-17.5,19c0.1,14.9,0.1,29.7,0.1,44.5v4.3
		C101.6,72.2,102.4,71.2,103.4,70.2 M171.9,21.9c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1
		S170.1,21.9,171.9,21.9 M157.7,13.3c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1S156.1,13.3,157.7,13.3 M143.4,4.2
		c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1S141.7,4.2,143.4,4.2 M104.3,26.8h4.2V59l-4.2,4.6V26.8z M116.7,50.1
		l-4.4,4.8V26.8h4.4V50.1z"
        />
        <path
          d="M234.1,125.6c-2.3,0-4,1.8-4,4c0,0.3,0,0.6,0.1,0.9l-10.4,9.8l-4.6-4.6l10-9.4c0.5,0.3,1.1,0.4,1.8,0.4
		c2.3,0,4-1.8,4-4s-1.8-4-4-4c-2.3,0-4,1.8-4,4c0,0.2,0,0.4,0.1,0.6l-10.5,9.8l-1.5-1.5V61.1c2-0.7,3.4-2.7,3.4-4.9
		c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3c0,2.3,1.4,4.2,3.4,4.9v71.8c0.8,0.9,1.7,1.7,2.5,2.6l-2.7,2.5l-7.1-7V67.7
		c2.1-0.7,3.7-2.7,3.7-5c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3c0,2.2,1.3,4,3.2,4.8v65.1l8.1,8l-3.3,3.1L189.3,132V74.6
		c2-0.7,3.4-2.7,3.4-4.9c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3c0,2.3,1.4,4.2,3.4,4.9v58.9c7.5,7.5,15.1,15,22.6,22.5
		c8.1-7.6,16.1-15.2,24.2-22.8c0.5,0.2,1,0.3,1.5,0.3c2.3,0,4-1.8,4-4C238.2,127.4,236.4,125.6,234.1,125.6 M227.1,120.7
		c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8C225.3,121.5,226.1,120.7,227.1,120.7 M206.3,56.1
		c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1C207.7,59.2,206.3,57.9,206.3,56.1 M195.4,62.7
		c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1C196.8,65.8,195.4,64.4,195.4,62.7 M212.6,138.2l4.6,4.6l-2.7,2.5
		l-4.6-4.6L212.6,138.2z M207.1,143.3l4.6,4.6l-3.3,3.1l-4.6-4.6L207.1,143.3z M184.6,69.7c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1
		s-1.4,3.1-3.1,3.1C185.9,72.8,184.6,71.4,184.6,69.7 M234.1,131.4c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
		C235.9,130.6,235.1,131.4,234.1,131.4"
        />
        <path
          d="M185.5,53.1c0.9-0.9,1.8-1.8,2.7-2.7L211.4,28c0-7.6,0-15.2,0-22.8h-3.8h-4.9h-3.8v18.7h-5v-8.2
		c2-0.7,3.4-2.7,3.4-4.9c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3c0,2.3,1.4,4.2,3.4,4.9v8.2h-0.9h-3.8h-0.1v3.7h0.1v25.5
		H185.5z M189,10.7c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1S189,12.5,189,10.7 M207.7,8.9v17.5l-4.9,4.8V8.9H207.7z
		 M199,27.7v7.2l-9.6,9.4V27.7H199z"
        />
      </g>
    </g>
  );
}
